* {
  box-sizing: border-box;
}

body,
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-weight: normal;
  background: #fafafa;
  background-color: #f9f6f2;
  color: #4f4f4f;
  min-height: 100%;
}

html {
  height: 100%;
}

.main-wrapper {
  height: 100%;
  z-index: 1;
}

.main-wrapper__side-nav {
  margin-left: 65px;
}

.main-wrapper__top-nav {
  margin-top: 75px;
}

.main-wrapper__link-to-home {
  padding-left: 24px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  top: 0;
}

.main-wrapper__content {
  position: relative;
  padding: 32px 16px 64px;
  min-height: 100%;
}

.hidden {
  display: none;
}
