.kritik-form__radio-control {
  margin: 0;
  margin-bottom: 6px;
  color: #4f4f4f;
}
.Mui-checked {
  font-weight: 500;
}
.kritik-form__radio-control--active > span {
  font-weight: 500;
}
.kritik-form__radio-control:last-child {
  margin-bottom: 0;
}
.kritik-form__radio {
  color: #4f4f4f !important;
  background: transparent;
  padding: 0;
  padding-right: 4px;
}
.kritik-form__radio:hover {
  background: transparent;
}
.kritik-form__radio > svg:first-child {
  color: #4f4f4f;
}
