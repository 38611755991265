.signup-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 900px;
  width: 100%;
  min-height: 768px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.signup-container__lpanel {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
}

.signup-container__rpanel {
  flex: 1;
}
@media only screen and (min-width: 800px) {
  .signup-container {
    flex-direction: row;
  }
  .signup-container__lpanel {
    max-height: 100%;
    width: 360px;
  }
}

.signup-media {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4844a3;
  height: 100%;
  width: 100%;
  &.success {
    background-color: $color-success;
  }
  &.error {
    background-color: $color-danger;
  }
}

.signup-media__graphic {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 24px 0;
}

.signup-media__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
}

.signup-content {
  background-color: #fff;
  padding: 32px 70px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: break-word;
  word-break: break-word;
}

.signup-content__header {
  margin-top: -8px;
  margin-bottom: 32px;
}

.signup-content__title-xl {
  font-size: 40px;
  color: #333333;
  line-height: 1.2em;
  margin: 0;
  font-weight: bold;
  margin-bottom: 24px;
}
.signup-content__body {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.signup-content__wrapper {
  width: 100%;
}
.signup-content__footer {
}

.signup-content__button {
  margin-top: 30px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  min-width: 100px;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  line-height: 32px;
  user-select: none;
  font-family: inherit;
  letter-spacing: 0.4px;
  outline: none;
  color: white;
  border: 1px solid #4844a3;
  background: #4844a3;
}

.signup-content__button:hover {
  background: #6d69b5;
}

.weight-strong {
  font-weight: 700;
}
.weight-default {
  font-weight: 400;
}
.signup-content__main-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 46px;
}

.signup-content__text {
  color: #4f4f4f;
  padding: 4px 0 16px;
}
